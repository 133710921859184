import React, { useContext } from 'react'
import Button from '@mui/material/Button'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import { GradeData } from 'type/gradeInfo'
import { TextField, Typography } from '@mui/material'
import { AuthContext } from 'contexts/authContext'
import { UserRole } from 'type/userInfo'

const useStyles = makeStyles((theme) => ({
  container: {
    marginLeft: 40,
    marginRight: 40,
    marginTop: 40,
    borderBottom: '1px solid #ccc',
  },
  contentWrapper: {
    marginBottom: '16px',
  },
  headerInput: {
    width: '100%',
    padding: '8px',
    marginBottom: '8px',
    borderRadius: '4px',
  },
  table: {
    width: '100%',
    marginBottom: '8px',
  },
  headerCell: {
    backgroundColor: '#f1f1f1',
    color: 'black',
    borderCollapse: 'collapse',
    padding: '12px',
  },
  largeCell: {
    width: '50%',
  },
  tableCell: {
    padding: '8px',
    textAlign: 'center',
  },
  button: {
    backgroundColor: '#ffd40a',
    marginBottom: 20,
    marginTop: 20,
    color: 'black',
    '&:hover': {
      backgroundColor: '#e2bf19',
    },
  },
}))

const GradeContentForm = ({
  contents,
  setContents,
  isGradeInChina,
}: {
  contents: GradeData[]
  setContents: any
  isGradeInChina: boolean
}) => {
  const classes = useStyles()
  const auth = useContext(AuthContext)
  const GRADE_ITEMS = isGradeInChina ? ['课程', '成绩'] : ['课程', '成绩', '缺课', '备注']
  const EMPTY_GRADE = isGradeInChina
    ? [
        ['政治', ''],
        ['语文', ''],
        ['数学', ''],
        ['地理', ''],
        ['物理', ''],
        ['化学', ''],
        ['生物', ''],
        ['历史', ''],
      ]
    : [
        ['', '', '', ''],
        ['', '', '', ''],
        ['', '', '', ''],
        ['', '', '', ''],
        ['', '', '', ''],
      ]

  const handleAddContent = () => {
    const newContent: GradeData = {
      id: new Date().getTime(),
      header: '',
      grades: EMPTY_GRADE,
    }
    setContents([...contents, newContent])
  }

  const handleHeaderChange = (index, value) => {
    const updatedContents = [...contents]
    updatedContents[index].header = value
    setContents(updatedContents)
  }

  const handleContentChange = (index, rowIndex, colIndex, value) => {
    if (colIndex !== 0 && colIndex !== 3 && !RegExp(/^[0-9]*$/).test(value)) {
      return
    }
    if (colIndex !== 0 && colIndex !== 3 && (value > 200 || value < 0)) {
      return
    }
    const updatedContents = [...contents]
    updatedContents[index].grades[rowIndex][colIndex] = value
    setContents(updatedContents)
  }

  const handleDeleteContent = (id) => {
    const updatedContents = contents.filter((content) => content.id !== id)
    setContents(updatedContents)
  }

  return (
    <div className={classes.container}>
      <Typography variant="h6">{isGradeInChina ? '中国成绩单' : '加拿大成绩单'}</Typography>
      {contents && contents.length > 0 ? (
        contents.map((content, index) => (
          <div key={content.id} id={isGradeInChina ? 'gradeinchina' : 'transcript'} className={classes.contentWrapper}>
            <TextField
              value={content.header}
              onChange={(e) => handleHeaderChange(index, e.target.value)}
              placeholder="i.e. 10年级上"
              disabled={auth.userInfo?.role === UserRole.Parent || auth.userInfo?.role === UserRole.Student}
              className={classes.headerInput}
            />
            <table className={classes.table}>
              <tbody>
                <tr key={0}>
                  {GRADE_ITEMS.map((col, colIndex) => (
                    <td key={colIndex} className={classNames(classes.headerCell)}>
                      <span>{col}</span>
                    </td>
                  ))}
                </tr>
                {content.grades.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {row.map((col, colIndex) => (
                      <td key={colIndex} className={classNames(classes.tableCell, colIndex === 3 && classes.largeCell)}>
                        <TextField
                          value={col}
                          multiline
                          disabled={
                            // Disable for parent role and student role
                            auth.userInfo?.role === UserRole.Parent || auth.userInfo?.role === UserRole.Student
                          }
                          onChange={(e) => handleContentChange(index, rowIndex, colIndex, e.target.value)}
                          style={{
                            width: '100%',
                            padding: '4px',
                            borderRadius: '4px',
                            border: 'none',
                          }}
                        />
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
            {
              // Disable for parent and student role
              auth.userInfo?.role === UserRole.Parent || auth.userInfo?.role === UserRole.Student ? null : (
                <Button variant="contained" onClick={() => handleDeleteContent(content.id)} className={classes.button}>
                  删除以上表格
                </Button>
              )
            }
          </div>
        ))
      ) : (
        <div style={{ marginTop: 20, marginBottom: 20 }}>暂无成绩单，请联系工作人员填写上传</div>
      )}
      {
        // Disable for parent and student role
        auth.userInfo?.role === UserRole.Parent || auth.userInfo?.role === UserRole.Student ? null : (
          <Button variant="contained" onClick={handleAddContent} className={classes.button}>
            添加成绩单
          </Button>
        )
      }
    </div>
  )
}

export default GradeContentForm
