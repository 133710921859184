import {
  Document,
  Packer,
  Paragraph,
  TextRun,
  AlignmentType,
  Table,
  TableRow,
  TableCell,
  WidthType,
  ImageRun,
  HeadingLevel,
  TableLayoutType,
} from 'docx'
import FileSaver from 'file-saver'
import { logoBase64 } from './base64Helpers'
import { GradeData, GradeInfo } from 'type/gradeInfo'
import { UserInfo } from 'type/userInfo'
import { FeedbackInfo } from 'type/feedbackinfo'

const createCell = (text) => {
  return new TableCell({
    children: [
      new Paragraph({ text, heading: HeadingLevel.HEADING_3, alignment: AlignmentType.LEFT, indent: { left: 50 } }),
    ],
  })
}

const fillContentWithDefault = (content: string) => {
  if (!content) {
    return '________'
  }
  return content
}

export const generateDocument = (userInfo: UserInfo, gradeInfo: GradeInfo, feedbacks: FeedbackInfo[]) => {
  const logo = new Paragraph({
    children: [new ImageRun({ data: logoBase64, transformation: { width: 580, height: 118 } })],
    alignment: AlignmentType.CENTER,
  })

  // Title
  const title = new Paragraph({
    text: '阳光学业规划表',
    heading: HeadingLevel.HEADING_1,
    alignment: AlignmentType.CENTER,
  })

  const emptyBlock = new Paragraph({})

  // Personal Information Fields
  const personalInfo = new Paragraph({
    text: '一、学生信息',
    heading: HeadingLevel.HEADING_2,
    children: [
      new TextRun(`
  中文全名: ${fillContentWithDefault(userInfo.name)}
  英文名: ${fillContentWithDefault(userInfo.ename)}
  出生年月日: ${fillContentWithDefault(userInfo.birthdate)}
  学校: ${fillContentWithDefault(userInfo.secondary_school)}
  初到加拿大入学时间: ${fillContentWithDefault(userInfo.secondary_startdate)}
  毕业计划时间: ${fillContentWithDefault(userInfo.graduation_date)}
  Email: ${fillContentWithDefault(userInfo.email)}
  电话: ${fillContentWithDefault(userInfo.phone_number)}
  微信: ${fillContentWithDefault(userInfo.wechat_id)}
  阳光主管老师: ${fillContentWithDefault(userInfo.general_manager)}
      `),
    ],
  })

  const goalInfo = new Paragraph({
    text: '二、目标大学和专业',
    heading: HeadingLevel.HEADING_2,
    children: [
      new TextRun(`
  目标大学: ________
  目标专业: ________
      `),
    ],
  })

  const gradeData: GradeData[] = gradeInfo?.grade_data?.map((grade) => {
    return JSON.parse(grade)
  })

  const gradesInfo = new Paragraph({
    text: '三、课程安排和成绩',
    heading: HeadingLevel.HEADING_2,
    children: [
      ...gradeData
        .map((grade) => {
          grade.grades.unshift(['课程', '成绩', '缺课', '备注'])
          return [
            new Paragraph({ text: `\n${grade.header}` }),
            new Table({
              layout: TableLayoutType.FIXED,
              width: {
                size: 100,
                type: WidthType.PERCENTAGE,
              },
              columnWidths: [2000, 2000, 2000, 3000],
              rows: grade.grades.map((row) => {
                return new TableRow({
                  children: row.map((cellText) => createCell(cellText)),
                })
              }),
            }),
          ]
        })
        .flat(),
    ],
  })

  const extraCurricular = [
    'Club',
    'Service',
    'Leadership',
    'Volunteer',
    'Family res.',
    'Athletics',
    'Arts',
    'Work',
    'Other',
    'Other',
    'Other',
  ]

  const extraCurricularInfo = new Paragraph({
    text: '四、课外活动',
    heading: HeadingLevel.HEADING_2,
    children: [
      new Table({
        layout: TableLayoutType.FIXED,
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        },
        columnWidths: [9000],
        rows: [
          new TableRow({
            children: [
              createCell(`  1. 课外活动
  A. Club B. Family/community Responsibilities 
  C. Creative or Perofrming Arts D.Work/Employment  
  E. Athletics  F. Volunteer. G. Service to others  
  H.Leadership. I. Others`),
            ],
          }),
        ],
      }),
      new Table({
        layout: TableLayoutType.FIXED,
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        },
        columnWidths: [500, 1500, 1000, 2000, 1000, 1000, 1000, 1000],
        rows: [
          new TableRow({
            children: [
              createCell(' '),
              createCell('类别'),
              createCell('活动名称'),
              createCell('具体描述'),
              createCell('开始时间（年月）'),
              createCell('结束时间（年月'),
              createCell('几次/几小时'),
              createCell('组织方'),
            ],
          }),
          ...extraCurricular.map((item, index) => {
            return new TableRow({
              children: [
                createCell((index + 1).toString()),
                createCell(item),
                createCell(''),
                createCell(''),
                createCell(''),
                createCell(''),
                createCell(''),
                createCell(''),
              ],
            })
          }),
        ],
      }),
      new Table({
        layout: TableLayoutType.FIXED,
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        },
        columnWidths: [9000],
        rows: [
          new TableRow({
            children: [createCell(`  2. 艺术/体育/音乐等方面特长`)],
          }),
        ],
      }),
      new Table({
        layout: TableLayoutType.FIXED,
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        },
        columnWidths: [500, 2500, 1000, 1000, 4000],
        rows: [
          new TableRow({
            children: [
              createCell(' '),
              createCell('名称'),
              createCell('学习时间'),
              createCell('程度'),
              createCell('级别、竞赛等'),
            ],
          }),
          new TableRow({
            children: [createCell('1'), createCell(''), createCell(''), createCell(''), createCell('')],
          }),
          new TableRow({
            children: [createCell('2'), createCell(''), createCell(''), createCell(''), createCell('')],
          }),
          new TableRow({
            children: [createCell('3'), createCell(''), createCell(''), createCell(''), createCell('')],
          }),
        ],
      }),
    ],
  })

  const academicInfo = new Paragraph({
    text: '五、学科竞赛和课外竞赛情况（数学、化学、物理、计算机、模联、商赛等）',
    heading: HeadingLevel.HEADING_2,
    children: [
      new Table({
        layout: TableLayoutType.FIXED,
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        },
        columnWidths: [500, 500, 2000, 2000, 2000, 2000],
        rows: [
          new TableRow({
            children: [
              createCell(' '),
              createCell('类别'),
              createCell('名称'),
              createCell('参加时间(年月)'),
              createCell('成绩'),
              createCell('主办方'),
            ],
          }),
          new TableRow({
            children: [createCell('1'), createCell(''), createCell(''), createCell(''), createCell(''), createCell('')],
          }),
          new TableRow({
            children: [createCell('2'), createCell(''), createCell(''), createCell(''), createCell(''), createCell('')],
          }),
          new TableRow({
            children: [createCell('3'), createCell(''), createCell(''), createCell(''), createCell(''), createCell('')],
          }),
          new TableRow({
            children: [createCell('4'), createCell(''), createCell(''), createCell(''), createCell(''), createCell('')],
          }),
          new TableRow({
            children: [createCell('5'), createCell(''), createCell(''), createCell(''), createCell(''), createCell('')],
          }),
        ],
      }),
    ],
  })

  const meetingInfo = new Paragraph({
    text: '六、会议记录',
    heading: HeadingLevel.HEADING_2,
    children: [
      new Table({
        layout: TableLayoutType.FIXED,
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        },
        columnWidths: [2000, 1000, 5000, 1000],
        rows: [
          new TableRow({
            children: [createCell('时间'), createCell('参加人员'), createCell('主要内容'), createCell('备注')],
          }),
          ...feedbacks.map((feedback) => {
            return new TableRow({
              children: [
                createCell(feedback.date),
                createCell(feedback.stakeholder),
                createCell(`人员:${feedback.person}\n沟通内容:${feedback.content}\n沟通结果:${feedback.result}`),
                createCell(feedback.followUp),
              ],
            })
          }),
        ],
      }),
    ],
  })

  // Add content to the document
  const doc = new Document({
    sections: [
      {
        children: [
          logo,
          title,
          emptyBlock,
          personalInfo,
          goalInfo,
          gradesInfo,
          extraCurricularInfo,
          academicInfo,
          meetingInfo,
        ],
      },
    ],
  })

  // Generate and download the document
  Packer.toBlob(doc).then((blob) => {
    FileSaver.saveAs(blob, '阳光学业规划表.docx')
  })
}
