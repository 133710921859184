// s3.js

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

const getCurrentTimeFormatted = () => {
  const now = new Date()

  const year = now.getFullYear()
  const month = now.getMonth() + 1 // getMonth() returns 0-11
  const day = now.getDate()
  const hour = now.getHours()
  const minute = now.getMinutes()

  // Padding single digit month, day, hour, and minute with leading zero
  const monthStr = month < 10 ? `0${month}` : `${month}`
  const dayStr = day < 10 ? `0${day}` : `${day}`
  const hourStr = hour < 10 ? `0${hour}` : `${hour}`
  const minuteStr = minute < 10 ? `0${minute}` : `${minute}`

  return `${year}${monthStr}${dayStr}${hourStr}${minuteStr}`
}

export const uploadFile = async (userId, authToken, file, type) => {
  try {
    const fileContent = await file.arrayBuffer()
    const uint8Array = new Uint8Array(fileContent)
    let binaryString = ''
    uint8Array.forEach((byte) => {
      binaryString += String.fromCharCode(byte)
    })
    const base64FileContent = btoa(binaryString)
    const filePath = `${userId}/${type}/${file.name}`

    const response = await fetch(`${API_ENDPOINT}/v1/users/${userId}/upload_file?file_path=${filePath}`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Authorization: authToken,
      },
      body: base64FileContent,
    })

    if (!response.ok) {
      console.error('Failed to upload file')
      return response
    }

    console.log('File uploaded successfully')
    return response
  } catch (error) {
    console.error('Error uploading file:', error)
    throw error
  }
}

export const uploadPublicFile = async (userId, authToken, file) => {
  try {
    const fileContent = await file.arrayBuffer()
    const uint8Array = new Uint8Array(fileContent)
    let binaryString = ''
    uint8Array.forEach((byte) => {
      binaryString += String.fromCharCode(byte)
    })
    const base64FileContent = btoa(binaryString)
    const timestamp = getCurrentTimeFormatted()
    const filePath = `public/${file.name}${timestamp}`

    const response = await fetch(`${API_ENDPOINT}/v1/users/${userId}/upload_file?file_path=${filePath}`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Authorization: authToken,
      },
      body: base64FileContent,
    })

    if (!response.ok) {
      console.error('Failed to upload file')
      return response
    }

    console.log('File uploaded successfully')
    return [response, filePath]
  } catch (error) {
    console.error('Error uploading file:', error)
    throw error
  }
}

export const downloadFile = async (userId, authToken, fileName, type) => {
  // Search the cache for the document path
  const filePath = `${userId}/${type}/${fileName}`

  try {
    const response = await fetch(
      `${API_ENDPOINT}/v1/users/${userId}/download_file?file_path=${encodeURIComponent(filePath)}`,
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          Authorization: authToken,
        },
      }
    )

    if (!response.ok) {
      throw new Error('Failed to download file')
    }

    const data = await response.json()

    // Decode the base64-encoded content to binary data
    const decodedContent = atob(data)

    // Convert the binary data to a Uint8Array
    const uint8Array = new Uint8Array(decodedContent.length)
    for (let i = 0; i < decodedContent.length; i++) {
      uint8Array[i] = decodedContent.charCodeAt(i)
    }

    // Create a Blob from the Uint8Array
    const blob = new Blob([uint8Array], { type: response.headers.get('Content-Type') })

    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = fileName
    document.body.appendChild(a)
    a.click()
    a.remove()
  } catch (error) {
    console.error('Error downloading file:', error)
    throw error
  }
}

export const listFiles = async (userId, authToken) => {
  try {
    const response = await fetch(`${API_ENDPOINT}/v1/users/${userId}/list_files`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: authToken,
      },
    })

    if (!response.ok) {
      console.error('Failed to fetch files')
      return response
    }

    const files = await response.json()
    return files ?? []
  } catch (error) {
    console.error('Error fetching files:', error)
    throw error
  }
}
