import React, { useContext, useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import GradeSheetComponent from '../GradeSheet/gradeSheet'
import S3FileUploaderDownloader from '../FileUploader/fileUploader'
import CommunicationDetailPage from '../Communication/communication'
import { AuthContext } from 'contexts/authContext'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { getEventsById, getFeedbackById, getGradeById, getUsers } from 'libs/route'
import { Box, Button, CircularProgress, Grid } from '@mui/material'
import { listFiles } from 'libs/s3'
import ProfileSheet from '../Profile/profileSheet'
import { UserInfo, UserRole } from 'type/userInfo'
import { generateDocument } from 'components/utils/docGenerator'
import { GradeInfo } from 'type/gradeInfo'
import { FeedbackInfo } from 'type/feedbackinfo'
import EventsPage from '../Events/events'
import { EventsInfo } from 'type/eventsInfo'

const useStyles = makeStyles((theme) => ({
  navigationBar: {
    display: 'flex',
    width: '100%',
    backgroundColor: '#f1f1f1',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
  },
  button: {
    flexGrow: 1,
    padding: '10px',
    border: 'none',
    backgroundColor: '#f1f1f1',
    cursor: 'pointer',
    color: '#333',
    fontWeight: 'bold',
    transition: 'background-color 0.3s',
    '&:hover': {
      backgroundColor: '#ddd',
    },
  },
  selectedButton: {
    backgroundColor: '#ddd',
  },
  root: {
    height: '100%',
  },
  title: {
    textAlign: 'center',
  },
  session: {
    width: '80vw',
    overflow: 'auto',
    overflowWrap: 'break-word',
    fontSize: '16px',
  },
  body: {
    backgroundColor: 'white',
    width: '100%',
    color: 'black',
    boxShadow: '4px 3px 5px 2px rgba(0, 0, 0, .1)',
    paddingBottom: '2vh',
  },
  headerButton: {
    backgroundColor: '#ffd40a',
    color: 'black',
    '&:hover': {
      backgroundColor: '#e2bf19',
    },
  },
  menu: {
    backgroundColor: '#ffd40a',
    color: 'black',
    boxShadow: '4px 0px 5px 2px rgba(0, 0, 0, .1)',
    padding: '7px',
    height: '50px',
  },
}))

const NavBar = () => {
  const classes = useStyles()
  const currentState = localStorage.getItem('navigation') ?? 'student-info'
  const [user, setUser] = useState({})
  const [selectedItem, setSelectedItem] = useState(currentState)
  const [grade, setGrade] = useState<GradeInfo>({})
  const [files, setFiles] = useState([])
  const [communications, setCommunications] = useState<FeedbackInfo[]>([])
  const [events, setEvents] = useState<EventsInfo[]>([])
  const [student, setStudent] = useState<UserInfo>({})
  const [parents, setParents] = useState<UserInfo[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const auth = useContext(AuthContext)
  const { id } = useParams<{ id: string }>()
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    const userInfo = auth.userInfo!
    setUser(userInfo)
  }, [auth.userInfo])

  useEffect(() => {
    localStorage.setItem('history', `${location.pathname}`)
  }, [location])

  useEffect(() => {
    async function getUserAllData(userId: string, token: string) {
      try {
        // Start all the asynchronous tasks simultaneously
        const [gradeInfo, fileList, feedbackInfo, parentsInfo, eventsInfo] = await Promise.all([
          getGradeById(userId, token),
          listFiles(student['username'], token),
          getFeedbackById(userId, token),
          getUsers(token, userId, UserRole.Parent),
          getEventsById(userId, token),
        ])

        setGrade(gradeInfo)
        setFiles(fileList)
        setCommunications(feedbackInfo)
        setParents(parentsInfo)
        setEvents(eventsInfo)
        setIsLoading(false)
      } catch (error) {
        // TODO: show a popup
        console.error('Error occurred:', error)
        setIsLoading(false)
      }
    }

    if (student && student['username']) {
      getUserAllData(student['username'], auth.sessionInfo?.idToken!)
    }
  }, [student, auth.sessionInfo?.idToken])

  useEffect(() => {
    const storedStudents = auth.userInfo!['students']
    if (storedStudents) {
      const students = storedStudents
      const student = students[id ? parseInt(id) : 0]
      setStudent(student)
    } else if (auth.userInfo!['role'] === UserRole.Student) {
      setStudent(auth.userInfo!)
    }
  }, [auth.userInfo, id])

  const handleItemClick = (item) => {
    setSelectedItem(item)
  }

  const renderContent = () => {
    if (selectedItem === 'grade-sheet') {
      return <GradeSheetComponent userInfo={student} gradeInfo={grade} />
    } else if (selectedItem === 's3-uploader') {
      return <S3FileUploaderDownloader userInfo={student} fileList={files} setFileList={setFiles} />
    } else if (selectedItem === 'communication') {
      return <CommunicationDetailPage userInfo={student} feedback={communications} setFeedback={setCommunications} />
    } else if (selectedItem === 'events') {
      return <EventsPage userInfo={student} events={events} setEvents={setEvents} />
    } else {
      return <ProfileSheet studentInfo={student} parentsInfo={parents} />
    }
  }

  const renderBackButton = () => {
    if (user['role'] === UserRole.Student) {
      return <div />
    }
    return (
      <Button
        className={classes.headerButton}
        onClick={() => {
          localStorage.removeItem('history')
          navigate('/profiles', { state: { selectedTab: 'student' }, replace: true })
          window.location.reload()
        }}
        id={'back'}
        variant="contained"
      >
        返回
      </Button>
    )
  }

  const renderDownloadStudentInfoButton = () => {
    if (user['role'] === UserRole.Student || user['role'] === UserRole.Parent) {
      return <div />
    }
    return (
      <Button
        className={classes.headerButton}
        onClick={() => {
          generateDocument(student, grade, communications)
        }}
        id={'download'}
        style={{ marginRight: '1vw' }}
        variant="contained"
      >
        学业规划表
      </Button>
    )
  }

  if (isLoading) {
    return (
      <>
        <Grid container alignItems="center">
          <Grid container className={classes.menu} direction="column" justifyContent="center" alignItems="flex-end" />
        </Grid>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '30',
          }}
        >
          <CircularProgress />
        </div>
      </>
    )
  }

  return (
    <>
      <Grid container alignItems="center">
        <Grid container className={classes.menu} direction="column" justifyContent="center" alignItems="flex-end">
          <Box display="flex" flexDirection="row" alignItems="center" style={{ position: 'relative', right: '1vw' }}>
            {renderDownloadStudentInfoButton()}
            {renderBackButton()}
          </Box>
        </Grid>
      </Grid>
      <div className={classes.navigationBar} id="navbar">
        <button
          className={`${classes.button} ${selectedItem === 'student-info' ? classes.selectedButton : ''}`}
          onClick={() => {
            handleItemClick('student-info')
            localStorage.setItem('navigation', 'student-info')
          }}
        >
          学生信息
        </button>
        <button
          className={`${classes.button} ${selectedItem === 'grade-sheet' ? classes.selectedButton : ''}`}
          onClick={() => {
            handleItemClick('grade-sheet')
            localStorage.setItem('navigation', 'grade-sheet')
          }}
        >
          成绩单
        </button>
        <button
          className={`${classes.button} ${selectedItem === 's3-uploader' ? classes.selectedButton : ''}`}
          onClick={() => {
            handleItemClick('s3-uploader')
            localStorage.setItem('navigation', 's3-uploader')
          }}
        >
          重要文件
        </button>
        {auth.userInfo?.role !== UserRole.Student && (
          <button
            className={`${classes.button} ${selectedItem === 'communication' ? classes.selectedButton : ''}`}
            onClick={() => {
              handleItemClick('communication')
              localStorage.setItem('navigation', 'communication')
            }}
          >
            学生沟通情况
          </button>
        )}
        <button
          className={`${classes.button} ${selectedItem === 'events' ? classes.selectedButton : ''}`}
          onClick={() => {
            handleItemClick('events')
            localStorage.setItem('navigation', 'events')
          }}
        >
          课外活动及社会实践
        </button>
      </div>
      {renderContent()}
    </>
  )
}

export default NavBar
